<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
    mounted(){
        this.Scaling();

        window.addEventListener('resize', ()=> {
            this.Scaling();
        });
    },
    methods:{
        Scaling(){
            var Ratio = window.innerWidth / 1280;
            if(Ratio > 1){
                document.getElementsByTagName("HTML")[0].style.fontSize = Ratio*62.5 + '%';
            } 
            else{
                document.getElementsByTagName("HTML")[0].style.fontSize = '62.5%';
            }
        }
        
    }
}
</script>
<style lang="scss">

/*===================Initialisation===================*/
@font-face {
    font-family: 'quicksand';
    src: url('./assets/font/Quicksand.ttf');
    font-weight: normal;
}
*, ::before, ::after{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

html{
    font-size: 62.5%;
    min-width: 320px;
    margin: auto;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: none; 
    
    body {
        background-color: $background-color;
        color: $color;
        font-family: quicksand;
        font-variation-settings: 'wght' 500;
        text-align: center;
        font-size: 1.6rem;

        h1  {font-size: 4rem;font-weight: 800;}
        h2  {font-size: 2.5rem;font-weight: 800;}
        h3  {font-size: 2rem;font-weight: 800;}
        h4  {font-size: 1.6rem;font-weight: 800;}
        b   {font-weight: 900;}
        a   {color: unset;text-decoration: underline;}
        u   {text-decoration: underline;}
        cite   {color: #797979;font-size: 1.2rem;font-style: normal;}

        .alert{
            display: block;
            width: fit-content;
            margin: auto;
            font-size: 1.2rem;
            color: white;
            background-color: #ed213a;
            padding: 0.5rem;
            border-radius: 0.5rem;
        }
        .valid{
            display: block;
            width: fit-content;
            margin: auto;
            font-size: 1.2rem;
            color: white;
            background-color: #0bb674;
            padding: 0.5rem;
            border-radius: 0.5rem;
        }
        /*======================Loader======================*/
        @keyframes load-finish{
            from  {opacity: 0;transform: translateY(2rem);}
            to    {opacity: 1;transform: translateY(0);}
        }
        header{opacity: 0;}
        main,footer{
            display: none;
            animation: load-finish .25s ease-in-out;
        }
        #loader{
            position: absolute;
            width: 50%;
            top: 50%;left: 50%;
            transform: translate(-50%,-50%);
        }
        /*===================Footer===================*/
        footer{
            padding: 0.5rem;
        }
        /*===================Section===================*/
        .container{
            margin: 1.5rem 1.5rem;
            padding: 1.5rem;
            border-radius: $radius;
            box-shadow: 1rem 1rem 1.5rem $darck-shadow,
                        -1rem -1rem 1.5rem #ffffff;
        }
        /*===================Flex position===================*/
        .columns {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;

            .column {
                flex: auto;
            }
        }
        /*===================Click to action button===================*/
        .cta-button{
            all: unset;
            display: block;
            position: relative;
            cursor: pointer;
            width: fit-content;
            width: -moz-fit-content;
            outline: none;
            margin: 1.5rem auto;
            padding: 1rem 4rem;
            font-size: 1.6rem;
            font-weight: 800;
            border-radius: 3rem;
            box-shadow: 1rem 1rem 1.5rem $darck-shadow,
                        -1rem -1rem 1.5rem #ffffff;
            
            &::after{
                content: "";
                position: absolute;
                width: 60%;
                height: 0.1em;
                bottom: 0.6rem;
                left: 20%;
                border-radius: 0.2rem;
                background-image: $deco-color;
            }

            &:active{
                box-shadow: inset 1rem 1rem 1.5rem $darck-shadow,
                            inset -1rem -1rem 1.5rem #ffffff;    
            }
        }
        /*===================Price===================*/
        .price{
            margin: 1rem auto;
            padding: 0.5rem 2rem;
            border-radius: 3rem;
            color: white;
            background-image: $deco-color;
        }
        .price2{
            position: relative;
        }
        .price2::after{
            content: '';
            display: block;
            position: relative;
            width: 5rem;
            height: 0.1em;
            margin: auto;
            bottom: -0.5rem;
            border-radius: 0.2rem;
            background-image: $deco-color;
        }
        /*===================Input===================*/
        input[type="range"],button{all: unset;cursor: pointer;}
        /*===================Table===================*/
        .table{
            overflow: auto;

            table{
                width: 50rem;
                margin: auto;
                border-collapse: collapse;
                
                tbody{background-color: white;}
                th,td{padding: 1rem;}
                td{border-right: 0.1rem solid #797979;}
                td:first-child{text-align: left;}
                td:last-child{border: none;}
                tr:nth-child(even){background-color: $background-color;}
                tr:hover {background-color: $darck-shadow;}
            }
        }
        /*=============================INPUT CHECK-RADIO=============================*/
        .check {
            display: inline-block;
            position: relative;
            padding-left: 1.8rem;
            margin: 0 1rem;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            color: $light-color;
        }
        
        /* Hide the browser's default radio button */
        .check input {
            all:unset;
        }
        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 1.6rem;
            width: 1.6rem;
            border: solid 0.2rem $light-color;
            border-radius: 50%;
        }
        /* On mouse-over, add a grey background color */
        .check:hover input ~ .checkmark {
            background-color: lightgray;
        }
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        /* Show the indicator (dot/circle) when checked */
        .check input:checked ~ .checkmark:after {
            display: block;
        }
        /* Style the indicator (dot/circle) */
        .check .checkmark:after {
            top: 0.1rem;
            left: 0.1rem;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-image: $deco-color;
        }


        /*=============================INPUT RANGE=============================*/

        input[type="range"]{
            display: inline-block;
            -webkit-appearance: none;
            outline: none;
            width: 18rem;
            height: 1rem;
            padding: 0 0.1rem;
            overflow: hidden;
            border: 0.2rem solid $light-color;
            border-radius: 1rem;
            cursor: ew-resize;
        }

        .range-value{
            color: $light-color;
            font-size: 1.6rem;
            margin: 0.5rem;
        }
        input[type="range"]::-webkit-slider-thumb{
            -webkit-appearance: none;
            width: 2rem;
            height: 0.8rem;
            border: none;
            border-radius: 0.4rem;
            background-image: $deco-color;
        }

        /*FF*/
        input[type="range"]::-moz-range-progress{
            height: 0.8rem;
            border-radius: 0.4rem;
            background-image: $deco-color;
        }
        input[type="range"]::-moz-range-thumb{
            visibility: hidden;
        }
        /*=============================INPUT Text/Mail/Tel=============================*/
        input[type="text"],input[type="tel"],input[type="mail"],input[type="password"]{
            all: unset;
            text-align: left;
            width: 22rem;
            padding: 0.5rem 1rem;
            margin: 0.5rem;
            border: 0.1rem solid #797979;
            border-radius: 2rem;
            background-color: white;
        }
    }
}
</style>
