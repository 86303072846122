<template>
  <header>
        <nav :class="{active: stateScroll}">
            <div class="menu-btn" @click="toggleMenu" :class="{active: stateMenu}">
                <div class="menu-btn-burger"></div>
            </div>

            <div id="brand"><!-- logo plus nom de la marque -->
                <img :src="require(`@/assets/${logo}`)" :alt="brandName">
                <span>{{brandName}}</span>
            </div>
                
            <ul class="menu-content"><!-- list des liens -->
                <li v-for="item in navLinks" :key="item.name">
                    <a @click="Scrolling(item.path)">{{item.name}}</a>
                </li>
                <li v-for="item in navSocial" :key="item.icon" class="iconlink">
                    <a :href="item.path" target="_blank" rel="noreferrer" >
                        <img :src="require(`@/assets/${item.icon}`)" :alt="item.icon">
                    </a>
                </li>					
            </ul>
            <span id="scrollPosition"></span>				
        </nav>
        <button @click="Top"><svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up-circle"><polyline points="16 12 12 8 8 12"></polyline><line x1="12" y1="16" x2="12" y2="8"></line></svg></button>
    </header>
</template>

<script>
export default {
    props: {
        logo: String,
        brandName: String,
        navLinks : Array,
        navSocial : Array,
    },
    data: () =>({
        stateMenu: false,
        stateScroll: false,
    }),
    created() {
        window.addEventListener('scroll', this.Scroll);
    },
    methods: {
        toggleMenu() {
            this.stateMenu = !this.stateMenu;// ouverture/fermeture du menu en mode tel/tablette
        },
        Top() {
            window.scrollTo(0,0);//retour en début de page
        },
        Scroll() {// bar de progression du scrolling de la page
            const progressBar = document.getElementById('scrollPosition');

            if(window.scrollY > 0){this.stateScroll = true}
            else{this.stateScroll = false}
            let totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            let Progress = (document.documentElement.scrollTop / totalHeight) * 100;
            progressBar.style.width = `${Progress}%`;
        },
        Scrolling(id) {//aller à un point d'encrage en prennent en compte la talle de la bar de navigation
            var elem = document.getElementById(id);
            var elemY = elem.offsetTop - 80;
            window.scrollTo(elem.offsetLeft,elemY); 
        }
    },
    
    name: 'navigation',
}
</script>

<style scoped lang="scss">
nav{
    height: 5rem;
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 50;

    #brand{
        display: block;
        line-height: 5rem;
        float: left;
        margin-left: 5rem;
        
        img{
            vertical-align: middle;
            width: 4rem;
        }
        span{
            margin-left: 1rem;
            vertical-align: middle;
        }
    }

    ul{
        float: right;
        margin-right: 1.5rem;

        li{
            display: inline-block;
            line-height: 5rem;
            margin: 0 2rem;
            position: relative;
            cursor: pointer;

            a{
                text-decoration: none;
            }
            &:hover{
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 0.15rem;
                    bottom: 0.8rem;
                    left: 0;
                    border-radius: 0.2rem;
                    background: $deco-color;
                    animation: extand 0.25s ease-in-out;

                    @keyframes extand {
                        0%{transform: scaleX(0);}
                        100%{transform: scaleX(1);}
                    }
                }
            }
            img{
                width: 1.8rem;
                vertical-align: middle;
            }
        }
    }
    .menu-btn{
        display: none;
        justify-content: center;
        align-items: center;
        float: right;
        margin-right: 4rem;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
        transition: all .5s ease-in-out;
        
        .menu-btn-burger{
            margin-top: 1.5rem;
            width: 2rem;
            height: 3px;
            background: $color;
            border-radius: 5px;
            transition: all .5s ease-in-out;

            &::before, &::after{
                content: '';
                position: absolute;
                width: 2rem;
                height: 3px;
                margin-left: -1rem;
                background: $color;
                border-radius: 5px;
                transition: all .5s ease-in-out;
            }
            &::before{
                transform: translateY(-6px);
            }
            &::after{
                transform: translateY(6px);
            }
        }
    }
    .menu-btn.active{
        .menu-btn-burger{
            transform: translateX(30px);
            background: transparent;

            &::before{transform: rotate(45deg) translate(-20px, 20px);}
            &::after{transform: rotate(-45deg) translate(-20px, -20px);}
        }
    }
    #scrollPosition{
        content: '';
        position: absolute;
        height: 0.1rem;
        width: 0;
        left: 0;top: 4.9rem;
        background-image: $deco-color;
    }
}
nav.active{
    border-bottom: 0.1rem solid $color;   
    background-color: $background-color;
}
button{
    position: fixed;
    width: 3rem;
    height: 3rem;
    bottom: 2rem;
    right: 2rem;
    z-index: 99;
    border-radius: 50%;
    border: 0.2rem solid white;
    background: $deco-color;
    cursor: pointer;
}
@media screen and (max-width : 920px){
    nav{
        #brand{
            float: none;
        }
        ul{
            float: none;
            margin-top: -1.8px;
            width: 50vw;
            background-color: $background-color;
            border-left: 1px solid black;
            height: 100vh;
            transform: translateX(100vw);
            transition: all .25s ease-in-out;

            li{
                margin: 0 1rem;
                &::before{
                    visibility: hidden;
                }
                img{
                    width: 2rem;
                }
            }
            li:not(.iconlink){
                display: list-item;
            }
        }
        .menu-btn{
            display: flex;
        }
        .menu-btn.active ~ ul{
            transform: translateX(50vw);
        }
    }
}
</style>
