<template>
    <div id="web-price" class="content" data-index="2">
        <div>
            <h4>Je souhaite :</h4>
            <div>
                <label class="check">Site vitrine
                    <input type="radio" name="categoryWeb" :value="400" v-model="categoryWeb">
                    <span class="checkmark"></span>
                </label>
                <label class="check">E-commerce
                    <input type="radio" name="categoryWeb" :value="900" v-model="categoryWeb">
                    <span class="checkmark"></span>
                </label>
            </div>
            <h4>Je fournie :</h4>
            <div>
                <label class="check">Texte
                    <input type="checkbox" name="Texte" :true-value="200" v-model="importContent[0]">
                    <span class="checkmark"></span>
                </label>
                <label class="check">Image
                    <input type="checkbox" name="image" :true-value="200" v-model="importContent[1]">
                    <span class="checkmark"></span>
                </label>
                <label class="check">Maquette
                    <input type="checkbox" name="maquette" :true-value="250" v-model="importContent[2]">
                    <span class="checkmark"></span>
                </label>
            </div>
            <h4>Nombres de page :</h4>
            <div>
                <label>
                    <input type="range" step="1" min="0" max="20" v-model="nbPage">
                    <span class="range-value" v-text="nbPage"></span>						
                </label>
            </div>
            <div class="Slider">
                <Slider :itemPerSlide="1" :nbSlide="9" btnStyle="S2">
                    <div class="slide">
                        <label class="check">Slider d'images et/ou de texte
                            <input type="checkbox" :true-value="80" v-model="option[0]">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="slide">
                        <label class="check">Portfolio
                            <input type="checkbox" :true-value="80" v-model="option[1]">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="slide">
                        <label class="check">Blog
                            <input type="checkbox" :true-value="150" v-model="option[2]">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="slide">
                        <label class="check">Calandrier d'événement
                            <input type="checkbox" :true-value="80" v-model="option[3]">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="slide">
                        <label class="check">Localisation Google Map
                            <input type="checkbox" :true-value="80" v-model="option[4]">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="slide">
                        <label class="check">Adresse mail personalisé<br>(contact@monsiteweb.com)
                            <input type="checkbox" :true-value="80" v-model="option[5]">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="slide">
                        <label class="check">Statistique(s)
                            <input type="checkbox" :true-value="1" v-model="option[6]">
                            <span class="checkmark"></span>
                        </label>
                        <div>
                            Nombres de Statistique(s) à analyser : 
                            <InputNumber v-model="nbStat" :min="0"/>
                        </div>
                    </div>
                    <div class="slide">
                        <label class="check">Mailing
                            <input type="checkbox"  :true-value="1" v-model="option[7]">
                            <span class="checkmark"></span>
                        </label>
                        <div>
                            Nombres de template(s) : 
                            <InputNumber v-model="nbMail" :min="0"/>
                        </div>
                    </div>
                    <div class="slide">
                        <label class="check">Formulaire(s)
                            <input type="checkbox" :true-value="1" v-model="option[8]">
                            <span class="checkmark"></span>
                        </label>
                        <div>
                            Nombres de formulaire : 
                            <InputNumber v-model="nbForm" :min="0"/>
                        </div>
                    </div>
                </Slider>
            </div>
            <cite>Pour tout autre(s) fonctionalitée(s) n'hésitez pas à me contacter !</cite>
            <table>
                <tbody>
                    <tr>
                        <td>Frais de réalisation</td>
                        <td>Maintenance mensuel</td>
                    </tr>
                    <tr>
                        <td><span class="price">{{price}} €</span></td>
                        <td><span class="price2">{{pricePerMounth}} €</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <cite>Ceci est une estimation, les prix affichées son à titre indicatif.</cite>
    </div>
</template>

<script>
import Slider from './Slider'
import InputNumber from './InputNumber'
export default {
    components: {
        Slider,
        InputNumber,
    },
    data: () => ({
        categoryWeb: 400,
        importContent: [200,200,250],
        nbPage: 1,
        option: [0,0,0,0,0,0,0,0,0],
        nbStat: 0,
        nbMail: 0,
        nbForm: 0,
    }),
    computed: {
        price: function () {
            return this.categoryWeb + (650 - (this.importContent[0] + this.importContent[1] + this.importContent[2])) + (30 * this.nbPage) + this.option[0] + this.option[1] + this.option[2] + this.option[3] + this.option[4] + this.option[5] + (this.option[6] * ((20 * this.nbStat) + 80)) + (this.option[7] * ((40 * this.nbMail) + 80)) + (this.option[8] * ((50 * this.nbForm) + 80))
        },
        pricePerMounth: function () {
            if(this.categoryWeb === 400){
                return 50;
            }
            else{
                return 100;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.Slider{
    max-width: 30rem;
    margin: 1rem auto;
    border: 0.1rem solid $light-color;
    border-radius: 1rem;

    .slide{
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
#web-price h4{
    margin-bottom: 0.5rem;
}
#web-price h4:nth-child(n+2){
    margin-top: 1rem;
}
#web-price table{
    width: 50%;
    margin: auto;
}
#web-price td{
    padding: 0.5rem;
}
@media only screen and (max-width: 720px){
    #web-price table{
        width: 100%;
    }
}
</style>