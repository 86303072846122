<template>
  <div class="slide-item" :style="'backgroundImage:url('+this.imgPortfolio+');padding-top: calc(((96% /'  + this.nbSlide  + ')*9)/16)'">
        <div class="moreInfo">
            <svg @click="description = !description" :class="{ 'is-active': description }" xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
        </div>
        <div :class="{ 'is-active': description }" class="slide-text">
            <h3>{{titlePortfolio}}</h3>
            <div v-html="descriptionPortfolio"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imgPortfolio: String,
        titlePortfolio: String,
        descriptionPortfolio: String,
        nbSlide: Number,
    },
    data() {
      return {
        description: false,
      }
    },
}
</script>

<style scoped lang="scss">

.slide-item{
    position: relative;
    width: 100%;
    margin: 0 0.5rem;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    border-radius: 1rem;
    border: 0.3rem solid #fff;
    overflow: hidden;
    transition: all 0.25s ease-in-out;

    &::after{
        content:'';
        position: absolute;
        top:0;left:0;bottom:0;right: 0;
        background-color: #fff;
        animation: loadImg 1s forwards;

        @keyframes loadImg {
            50%{opacity: 1;}
            99%{transform: translateY(0);}
            to{transform: translateY(100%);opacity: 0;}
        }
    }
}
.moreInfo{    
    position: absolute;
    top: 0;right: 0;
    border-radius: 0 0 0 1rem;
    background-color: white;
    z-index: 1; 
    width: 2.4rem;height: 2.4rem;
}
svg{
      
    transition: all 0.25s ease-in-out;
}
svg.is-active{
    transform: rotate(45deg);
}
.slide-text{
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    color: #fff;
    background-color: #333;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    transform: translateX(-110%);
    transition: all 0.25s ease-in-out;
    
}
.slide-text.is-active{
    transform: translateX(0);
}
.slide-text h3{
    margin-bottom: 1rem;
}
</style>