<template>
  <div class="number-input">
        <span :class="{disabled: decrementDisabled}" @click="decrement">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="8" y1="12" x2="16" y2="12"></line></svg>
        </span>
        <input
            type="number"
            title="number"
            v-model.number="currentValue"
            :disabled="inputDisabled"
            :min="min"
            :max="max"
            :step="step"
            :class="{ 'is-desactive': unViewNum }"
            @blur="currentValue = value"
            @keydown.esc="currentValue = value"
            @keydown.enter="currentValue = value"
            @keydown.up.prevent="increment"
            @keydown.down.prevent="decrement"
        />
        <span :class="{disabled: incrementDisabled}" @click="increment">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        disabled: Boolean,
        max: {
            type: Number,
            default: Infinity
        },
        min: {
            type: Number,
            default: -Infinity
        },
        value: {
            type: Number,
            default: 0
        },
        step: {
            type: Number,
            default: 1
        },
        unViewNum: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            currentValue: this.value,
            decrementDisabled: false,
            incrementDisabled: false,
            inputDisabled: false,
        }
    },
    methods: {
        increment() {
            if (this.disabled || this.incrementDisabled) {
                return;
            }

            let newVal = this.currentValue + 1 * this.step;
            this.decrementDisabled = false;

            this._updateValue(newVal);
        },
        decrement() {
            if (this.disabled || this.decrementDisabled) {
                return;
            }

            let newVal = this.currentValue + -1 * this.step;
            this.incrementDisabled = false;

            this._updateValue(newVal);
        },
        _updateValue(newVal) {
            const oldVal = this.currentValue;

            if (oldVal === newVal || typeof this.value !== 'number') {
                return;
            }
            if (newVal <= this.min) {
                newVal = this.min;
                this.decrementDisabled = true;
            }
            if (newVal >= this.max) {
                newVal = this.max;
                this.incrementDisabled = true;
            }
            this.currentValue = newVal;
            this.$emit('input', this.currentValue); //renvoie de la valeur
            this.$emit('change');
        }
    },

    mounted() {
        if (this.value == this.min) {
            this.decrementDisabled = true;
        }
    }

}
</script>

<style scoped lang="scss">
.number-input{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.5rem;
}
span {
    display: block;
    outline:none;
    -webkit-appearance: none;
    border: none;
    width: 1.8rem;
    background-color: transparent;
    cursor: pointer;

    &.disabled{
        color: $light-color;
    }
}
input[type="number"] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

.number-input input[type=number] {
    border: none;
    width: 3rem;
    font-size: 1.5rem;
    margin-top: -0.5rem;
    text-align: center;
}
.is-desactive{display: none;}
</style>