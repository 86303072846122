<template>
    <div id="editWysiwyg">
        <div class="Space">
          <button type="button" @click="commande('undo')" >
                <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-ccw"><polyline points="1 4 1 10 7 10"></polyline><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path></svg>
            </button>
            <button type="button" @click="commande('redo')">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-rotate-cw"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
            </button>  
        </div>
        <div class="Space">
            <button type="button" style="font-weight: bold;" @click="commande('bold')">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bold"><path d="M6 4h8a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path><path d="M6 12h9a4 4 0 0 1 4 4 4 4 0 0 1-4 4H6z"></path></svg>
            </button>
            <button type="button" style="font-style: italic;" @click="commande('italic')">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-italic"><line x1="19" y1="4" x2="10" y2="4"></line><line x1="14" y1="20" x2="5" y2="20"></line><line x1="15" y1="4" x2="9" y2="20"></line></svg>
            </button>
            <button type="button" style="text-decoration: underline;" @click="commande('underline')">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-underline"><path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3"></path><line x1="4" y1="21" x2="20" y2="21"></line></svg>
            </button>
            <button type="button" @click="commande('createLink')">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link-2"><path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            </button>
        </div>
        <div class="Space">
            <button type="button" @click="commande('insertOrderedList')">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="currentColor" stroke="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M2.5,16h2v.5h-1v1h1V18h-2v1h3V15h-3Zm1-7h1V5h-2V6h1Zm-1,2H4.3L2.5,13.1V14h3V13H3.7l1.8-2.1V10h-3Zm5-5V8h14V6Zm0,12h14V16H7.5Zm0-5h14V11H7.5Z"></path></svg>
            </button>
            <button type="button" @click="commande('insertUnorderedList')">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
            </button>
        </div> 
        <div class="Space">
            <Dropdown dropTitle="Format">
                <button type="button" @click="commande('formatBlock', 'h2')">
                    Titre
                </button><br>
                <button type="button" @click="commande('formatBlock', 'h3')">
                    Sous-titre
                </button><br>
                <button type="button" @click="commande('formatBlock', 'p')">
                    Corps
                </button>
            </Dropdown>

            <Dropdown dropTitle="Align" class="Space">
                <button type="button" @click="commande('justifyLeft')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-left"><line x1="17" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="17" y1="18" x2="3" y2="18"></line></svg>
                </button><br>
                <button type="button" @click="commande('justifyCenter')">    
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-center"><line x1="18" y1="10" x2="6" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="18" y1="18" x2="6" y2="18"></line></svg>
                </button><br>
                <button type="button" @click="commande('justifyRight')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-right"><line x1="21" y1="10" x2="7" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="7" y2="18"></line></svg>
                </button><br>
            </Dropdown>
        </div>
        
               

        <div id="wysiwyg" contentEditable="true" spellcheck="true" ref="edit" :placeholder="placeholder" v-on="{keydown: result,input: result, keyup: result, paste: result,}" v-html="innerText"></div>
        <span>{{StringLength}}/{{maxStringLength}}</span>
    </div>
</template>

<script>
import Dropdown from './Dropdown'
export default {
    components: {
        Dropdown,
    },
    props: {
        placeholder: String,
        innerText: String,
        maxStringLength: {
            type: Number,
            default: 100,
        }
    },
    data: () => {
        return {
            edit: '',
            resultat: '',
            StringLength: 0,
        }
    },
    methods: {
        commande(nom, argument) {
            if (typeof argument === 'undefined') {
                argument = '';
            }
            if(nom === 'createLink'){
                argument = prompt('URL du lien :');
                if(!argument){return;}
            }
            // Exécuter la commande
            document.execCommand(nom, false, argument);
        },

        result(e) {
            this.StringLength = this.$refs.edit.innerText.length;
            if(e.type === 'paste'){
                var pasteText = e.clipboardData.getData('text').length;
                this.StringLength += pasteText;

                e.preventDefault();
                var text = (e.originalEvent || e).clipboardData.getData('text/plain');
            }
            if (this.StringLength >= this.maxStringLength && e.keyCode != 8 && e.keyCode != 37 && e.keyCode != 38 && e.keyCode != 39 && e.keyCode != 40 && e.keyCode != 46 ) {
                e.preventDefault();                              
            }
            else{
                this.resultat = this.$refs.edit.innerHTML;
                this.$emit('input', this.resultat);
                if(e.type === 'paste'){
                    document.execCommand("insertHTML", false, text);
                }
            }
            
        }
    }
}
</script>

<style scoped lang="scss">
#editWysiwyg{
    width: 100%;
    margin: 0.5rem auto;
    color: #333;
    background-color: whitesmoke;
    border: 0.1rem solid #797979;
    border-radius: 1rem;
    overflow: hidden;
    user-select: none;

    #wysiwyg{
        width: 100%;
        height: 18rem;
        padding: 2rem;
        background-color: white;
        text-align: left;
        overflow-y: scroll;
        user-select: text;
    }
}
button{
    padding: 0.5rem;
    padding-bottom: 0;;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    cursor: pointer;

    &:active, &:focus, &:hover{
        background-color: lightgray;
    }
}

.Space{
    display: inline-block;
    margin: 0 0.5rem;
}
[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: gray;
  background-color: transparent;
}
</style>
<style>
#wysiwyg ul{
    display: list-item;
    padding-left: 4rem;
}
#wysiwyg ul li{
    list-style: disc;
}
#wysiwyg ol{
    display: list-item;
    padding-left: 4rem;
}
#wysiwyg ol li{
    list-style: decimal;
}
#wysiwyg a{
    text-decoration: underline;
    color: royalblue;
}
</style>