// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/Deco.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#contact[data-v-5362fcf2]:after,#contact[data-v-5362fcf2]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.aboutSlide[data-v-5362fcf2]{text-align:left;width:100%}.aboutSlide h3[data-v-5362fcf2]{padding-bottom:1rem}#about[data-v-5362fcf2],#contact[data-v-5362fcf2]{max-width:calc(50% - 3rem)}#contact[data-v-5362fcf2]{display:flex;position:relative;flex-direction:column;justify-content:space-between;overflow:hidden}#contact[data-v-5362fcf2]:after,#contact[data-v-5362fcf2]:before{content:\"\";position:absolute;width:25%;height:100%;background-repeat:no-repeat;background-size:100%}#contact[data-v-5362fcf2]:before{top:40%;left:0}#contact[data-v-5362fcf2]:after{transform:scale(-1);top:-40%;left:75%}#contact img[data-v-5362fcf2]{width:15rem;height:15rem;z-index:5;margin:1rem auto;border-radius:50%;border:5px solid #fff}@media only screen and (max-width:720px){#about[data-v-5362fcf2],#contact[data-v-5362fcf2]{max-width:calc(100% - 3rem)}}.Vue p[data-v-5362fcf2]{-webkit-animation:vue-data-v-5362fcf2 1s ease-in-out infinite;animation:vue-data-v-5362fcf2 1s ease-in-out infinite;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;width:moz-fit-content;padding:0 1rem;background-image:linear-gradient(135deg,#00dbde,#fc00ff);-webkit-background-clip:text;background-clip:text;margin:auto}@-webkit-keyframes vue-data-v-5362fcf2{50%{color:transparent;transform:scale(1.1)}}@keyframes vue-data-v-5362fcf2{50%{color:transparent;transform:scale(1.1)}}", ""]);
// Exports
module.exports = exports;
