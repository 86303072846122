<template>
  <article>
    <div class="tabs">
        <ul>
            <button @click="tabChange(false)" id="prev" ref="prev">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
            </button>
            <li v-for="(tab, index) in tabs" :key="index">
                <div class="nav-item"
                    :class="{ 'is-active': tab.isActive }"
                    @click="selectTab(tab)">
                    
                    {{ tab.name }}
                    
                </div>
            </li>
            <button @click="tabChange(true)" id="next" ref="next">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </button>
        </ul>
    </div>
    <section class="tabs-details">
        <slot></slot>
    </section>
  </article>
</template>

<script>
  export default {
    data: () => {
      return {
        tabs: []
      }
    },
    methods: {
        selectTab(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = tab.name === selectedTab.name;
            });
        },
        tabChange(direction) {
            let i;
            this.tabs.forEach((tab,index) => {
                if(tab.isActive){
                    i= index;
                }
                if(direction){
                   if(index < this.tabs.length){   
                        if(index ===(i+1)) {
                            this.tabs.forEach((tab,index) => {
                                if(index === (i+1)){
                                    tab.isActive = tab.name;
                                }
                                else{tab.isActive = false}
                            });  
                        }     
                    }
                }
                else{                    
                    if(i > 0){
                        this.tabs.forEach((tab,index) => {
                            if(index === (i-1)){
                                tab.isActive = tab.name;
                            }
                            else{tab.isActive = false}
                        });
                    } 
                }
            });
        }
    },
    created() {
      this.tabs = this.$children;
    }
  }
</script>

<style  scoped lang="scss">
  .tabs {
    margin: 0 1rem;
  }
  .tabs-details {
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    background-color: white;
  }
  ul {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;

    button{
        margin: 1.5rem 0.5rem;
    }
    .nav-item {
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        margin: 1rem 0.5rem;
        position: relative;
        cursor: pointer;
        border-radius: 10rem;
        background-clip: padding-box;
        border: solid 0.2rem transparent;
        background-color: #ECF0F3;
        color: $color;

        &::after{
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -1;
            margin: -0.2rem;
            border-radius: inherit;
            background: linear-gradient(to right, #00DBDE, #FC00FF);
            transform: scaleX(0);
            transition: all 0.25s ease-in-out;
        }
        &:hover::after{
            transform: scaleX(1);
        }
        &.is-active {
            background-color: white;
            &::after{
                transform: scaleX(1);
            }
        }
    }
  }
@media only screen and (max-width: 820px) {
    .nav-item:not(.is-active){
        display: none;
    }
}
</style>