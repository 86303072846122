<template>
    <div class="containSlider">
        <div class="slider" ref="slider">
            <slot/>
        </div>
        <div>    
            <span @click="prev" class="slide-btn" :class="btnStyle"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><polygon points="20 4 8 12 20 20"></polygon></svg></span>
            <div class="slide-prog-contain">
                <span class="slide-prog" ref="progress"></span>
            </div>
            <span @click="next" class="slide-btn " :class="btnStyle"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><polygon points="4 4 16 12 4 20"></polygon></svg></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemPerSlide: Number,
        nbSlide: Number,
        btnStyle: {
            type: String,
            default: 'S1',
        },
    },
    data: function () {
        return {
            offset: 0,
            slide: 0,
            page: 0,
            item: 0,
            oldScreenWidth: 0,
        }
    },
    mounted() {
        var touchstartX = 0;
        var touchendX = 0;
        this.resize();
        this.init();

        window.addEventListener('resize', ()=>{//detection du changement de taille du de la fenêtre du navigateur
            if(window.innerWidth != this.oldScreenWidth){
                this.resize();
            }
        });

        //avencer ou reculer le slider ay=u slide du toucher
        this.$refs.slider.addEventListener('touchstart', (event) => {
            touchstartX = event.changedTouches[0].screenX;
        });
        this.$refs.slider.addEventListener('touchend', (event) => {
            touchendX = event.changedTouches[0].screenX;
            if((touchendX - touchstartX) > 50){ this.prev();}
            if((touchendX - touchstartX) < -50){ this.next();}
        });
    },
    watch: { // retravailler la taille des item si ils sont ajouter ou enlever
        nbSlide: function (val, oldVal){
            if(val !== oldVal)this.resize();
        }
    },
    methods:{
        resize(){
            if(window.innerWidth < 900){
                if(this.itemPerSlide >= 2){
                    this.item = 2;
                    if(window.innerWidth < 600){
                        if(this.itemPerSlide >= 1){
                            this.item = 1;
                        }
                    }
                }
                else{this.item = this.itemPerSlide;}
            }
            else{this.item = this.itemPerSlide;}
            this.offset = 0;
            this.oldScreenWidth = window.innerWidth;
            this.init();
        },
        init() {
            this.slide = this.nbSlide * (100/this.item);
            this.$refs.slider.style.width = this.slide.toString() + '%';
            this.$refs.slider.style.left = this.offset.toString() + '%' ;
            this.prog();
        },
        prev() {
            if(this.offset < 0){
                this.offset += 100;
                this.$refs.slider.style.left = this.offset.toString() + '%' ;
                this.prog();
            }
            
        },
        next() {
            if(this.offset > -(this.slide)+100){
                this.offset -= 100;
                this.$refs.slider.style.left = this.offset.toString() + '%' ;
                this.prog();
            }
        },
        prog() {
            this.page = (((this.offset-100)/-100) / Math.ceil(this.nbSlide/this.item))*100;
            this.$refs.progress.style.width = 'calc(' + this.page.toString() + '% - 0.2rem)';
        }
    },
    name: 'Slider',
}
</script>

<style scoped lang="scss">
.containSlider{
    width: 100%;
    overflow: hidden;
    margin: 1rem 0;
}
.slider {
    display: flex;
    width: 100%;
    left: 0;
    flex-direction: row;
    position: relative;
    justify-content: space-around;
    transition: all 0.25s ease-in-out;

   .slide{
       padding: 1rem;
       margin: 1rem;
       position: relative;
       width: 100%;
   }
}
.slide-btn{
    display: inline-block;
    margin: 1.5rem 1rem;
    cursor: pointer;

    svg{
        position: relative;
        width: 1.8rem;
        height: 1.8rem;
        vertical-align: middle;
        margin: auto;
        fill: none;
        stroke: $light-color;
        stroke-width: 2.5;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}
.slide-btn.S1{
    width: 5rem;
    height: 3rem;
    padding: 0.4rem;
    background-color: #ECF0F3;
    box-shadow: 0.5rem 0.5rem 0.8rem #D1D9E6,
                -0.5rem -0.5rem 0.8rem #ffffff;
    border-radius: 1.5rem;

    svg{
        fill: $light-color;
    }
    
    &:active{
        background-color: transparent;
        box-shadow: inset 0.5rem 0.5rem 1rem #D1D9E6,
                    inset -0.5rem -0.5rem 1rem #ffffff;
    }
}
.slide-prog-contain{
    content: '';
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 10rem;
    height: 1.2rem;
    border: 0.2rem solid #797979;
    border-radius: 1rem;

    .slide-prog{
        content: '';
        display: inline-block;
        position: absolute;
        top: 0.1rem;
        left: 0.1rem;
        width: calc(100% - 0.2rem);
        height: 0.6rem;
        border-radius: 1rem;
        background-image: $deco-color;
        transition: all 0.25s ease-in-out;
    }
}
</style>