<template>
  <div id="404">
    <h2>Wrong way !</h2>
    <img src="@/assets/404.png">
    <router-link to="/" >Go back home</router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
h2{
  margin: 2rem;
}
a{
  display: block;
  width: fit-content;
  color: white;
  background-image: $deco-color;
  padding: 0.5rem 2rem;
  margin: 2rem auto;
  border-radius: 3rem;
}
img{
  max-width:90%;
  max-height:50vh; 
}
</style>