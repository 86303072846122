<template>
  <div class="admin">
    <h1>Administrer mon site web</h1>

    <div class="container">
      <h2>Contact</h2>
      <img :src="formProfil[4]" alt="profil" style="width: 10rem;border:5px solid white;borderRadius:50%;">
      <InputImage :maxFileSize="100000" :idIN="0" v-model="formProfil[0]" @input="formProfil[4] = getPreviewImg(formProfil[0])"/>
      <form action="javascript:void(0);">
        <input type="tel" placeholder="Tel :" autocomplete="tel" v-model="formProfil[1]">
        <input type="mail" placeholder="Mail :" autocomplete="email" v-model="formProfil[2]"/>
        <button type="submit" class="cta-button" @click="formProfilValidation()" >Modifier mon profil</button>
        <p class="valid" v-html="validMsg.profil" v-show="validMsg.profil !== ''"></p>
        <p class="alert" v-html="errorMsg.profil" v-show="errorMsg.profil !== ''"></p>
      </form>
    </div>
    
    <div class="container">
      <h2>Portfolio</h2>
      <h3>Nouvelle création :</h3>
        <div id="portfolio">
          <input type="text" placeholder="Titre" name="titleCreation" v-model="formPortfolio[0]"/>
          <InputImage :maxFileSize="200000" :aspectRotioX="16" :aspectRotioY="9" :idIN="1" v-model="formPortfolio[1]" @input="updatePortfolio.img = getPreviewImg(formPortfolio[1])"/>
          <TextEditor placeholder="Desciption..." :innerText="updatePortfolio.description" v-model="formPortfolio[2]"/>
          <Dropdown dropTitle="Type de projets">
              <label class="check">logo
                  <input type="radio" name="category" value="logo" v-model="formPortfolio[3]" >
                  <span class="checkmark"></span>
              </label><br>
              <label class="check">web design
                  <input type="radio"  name="category" value="web" v-model="formPortfolio[3]">
                  <span class="checkmark"></span>
              </label><br>
              <label class="check">Autres
                  <input type="radio"  name="category" value="other" v-model="formPortfolio[3]">
                  <span class="checkmark"></span>
              </label>
          </Dropdown>
          <PortfolioSlide v-show="formPortfolio[1] != null" :imgPortfolio="updatePortfolio.img" :titlePortfolio="formPortfolio[0]" :descriptionPortfolio="formPortfolio[2]" :nbSlide="3" style="width:28rem;margin:auto;padding-top: 16rem"/>
          <button type="submit" class="cta-button" @click="formPortfolioValidation(null)" v-if="!updatePortfolio.update" >Enregistrer ma création</button>
          <button type="submit" class="cta-button" @click="formPortfolioValidation(updatePortfolio.id)" v-if="updatePortfolio.update" >Modifier ma création</button>
          <p class="valid" v-html="validMsg.portfolio" v-show="validMsg.portfolio !== ''"></p>
          <p class="alert" v-html="errorMsg.portfolio" v-show="errorMsg.portfolio !== ''"></p>
        </div>
      <h3>Toute vos Création :</h3>
      <div class="table">
        <table>
          <thead>
              <th>Titre</th>
              <th>Description</th>
              <th>Image</th>
              <th>Type</th>
              <th>Modifier</th>
              <th>Supprimer</th>
          </thead>
          <tbody>
              <tr v-for="(item, index) in PortfolioItem" :key="index">
                <td>{{item.title}}</td>
                <td v-html="item.description"></td>
                <td><img :src="item.image" :alt="item.title" width="100"></td>
                <td>{{item.type}}</td>
                <td>
                  <button @click="modifyCreation(index)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                  </button>
                </td>
                <td>
                  <button @click="deleteCreation(item._id)" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><circle cx="12" cy="12" r="10"></circle><line x1="16" y1="8" x2="8" y2="16"></line><line x1="8" y1="8" x2="16" y2="16"></line></svg>
                  </button>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="container">
      <h2>Gérer les administrateurs du site</h2>
      <div>
        <h4>Ajouter un administrateur :</h4>
        <form action="javascript:void(0);">
          <input type="mail" placeholder="Mail :" v-model="formAdmin[0]">
          <input type="password" placeholder="Mot de passe :" v-model="formAdmin[1]">
          <input type="password" placeholder="Confirmer le mot de passe :" v-model="formAdmin[2]">
          <button type="submit" class="cta-button" @click="formValidAdmin()" >Ajouter</button>
          <p class="valid" v-html="validMsg.admin" v-show="validMsg.admin !== ''"></p>
          <p class="alert" v-html="errorMsg.admin" v-show="errorMsg.admin !== ''"></p>
        </form>
        
        
      </div>
      <div>
        <h4>Liste des administrateurs</h4>
        <div class="table">
          <table>
            <thead>
                <th>Id</th>
                <th>Email</th>
                <th>Supprimer</th>
            </thead>
            <tbody>
                <tr v-for="(item, index) in adminList" :key="index">
                  <td>{{index}}</td>
                  <td>{{item.mail}}</td>
                  <td>
                    <button @click="deleteAdmin(item._id)" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><circle cx="12" cy="12" r="10"></circle><line x1="16" y1="8" x2="8" y2="16"></line><line x1="8" y1="8" x2="16" y2="16"></line></svg>
                    </button>
                  </td>
                </tr>
            </tbody>
          </table>          
        </div>
      </div>
    </div>
    <router-link to="/" class="cta-button" >Page d'acceuille</router-link>
    <button  style="margin-bottom: 2rem;text-decoration: underline;cursor: pointer" @click="deconnection()">
      <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
    </button>
  </div>
</template>

<script>
import InputImage from "@/components/InputImage";
import TextEditor from "@/components/TextEditor";
import Dropdown from "@/components/Dropdown";
import PortfolioSlide from "@/components/PortfolioSlide";
export default {
  components: {
    InputImage,
    TextEditor,
    Dropdown,
    PortfolioSlide,
  },
  data: () => {
    return {
      session: {
        adminId: '',
        token: ''
      },

      formProfil: [null,null,null,null,null],

      PortfolioItem: {},
      formPortfolio: [null,null,null,null],
      updatePortfolio: {
        id: Number,
        update: false,
        description: '',
        img: '',
      },

      formAdmin: [null,null,null],
      adminList: {},

      errorMsg: {
        profil:'',
        portfolio: '',
        admin: ''
      },
      validMsg: {
        profil:'',
        portfolio: '',
        admin: '',
      },
    };
  },
  mounted(){
    this.session.adminId = localStorage.getItem('adminId');
    this.session.token = localStorage.getItem('token');
    this.Profil();
    this.Creation();
    this.Admin();
    
  },
  methods: {
    Profil(){
      this.axios
        .get('/api/profil')
        .then(response => {
          this.formProfil = [response.data[0].image,response.data[0].tel,response.data[0].mail,response.data[0]._id,response.data[0].image];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formProfilValidation(){
      for(let i=0; i<=(this.formProfil.length - 2); i++){
        if (this.formProfil[i] === null || this.formProfil[i] === '') {
          this.errorMsg.profil = 'Veuillez remplir tout les champs'
          return false;
        }
      }
      if(this.formProfil[1].indexOf('+33')!=-1) this.formProfil[1] = this.formProfil[1].replace('+33', '0');
      var regex = /^0[1-7]\d{8}$/;
      if(regex.test(this.formProfil[1])){
        regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(regex.test(this.formProfil[2])){
          this.modifyProfil();
          }
        else{
          this.errorMsg.profil = 'Veuillez choisir un Email valide !'
        }
      }
      else{
        this.errorMsg.profil = 'Veuillez choisir un numéro de téléphone valide !'
      }
    },
    modifyProfil(){
      var Update;

      if(typeof(this.formProfil[0]) === 'string'){
        Update = new FormData();
        Update.append('adminId', this.session.adminId);
        Update.append('image', this.formProfil[0]);
        Update.append('tel', this.formProfil[1]);
        Update.append('mail', this.formProfil[2]);
      }
      else{
        Update = new FormData();
        Update.append('adminId', this.session.adminId);
        Update.append('image', this.formProfil[0], 'profil.png');
        Update.append('tel', this.formProfil[1]);
        Update.append('mail', this.formProfil[2]);
      }
      this.axios
        .put('/api/profil/'+ this.formProfil[3], Update,  {headers: {'Authorization': 'Bearer '+ this.session.token}})
        .then(response => {
          console.log(response.data);
          this.errorMsg.profil = '';
          this.validMsg.profil = 'Profil mis à jour';
          setTimeout(()=>{this.validMsg.profil = '';}, 2000);
          this.Profil();
        })
        .catch(function (error) {
          console.log(error);
          this.errorMsg.profil = 'Une erreur c\'est produite, veuillez réessayer !'
        });
    },
    Creation(){
      this.axios
        .get('/api/portfolio')
        .then(response => {
            this.PortfolioItem = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formPortfolioValidation(index){
      for(let i=0; i<=this.formPortfolio.length; i++){
        if (this.formPortfolio[i] === null || this.formPortfolio[i] === '') {
          this.errorMsg.portfolio = 'Veuillez remplir tout les champs'
          return false;
        }
      }
      if (this.formPortfolio[0].length < 30) {
          if(index === null){
            this.newCreation();
          }
          else{
            this.updateCreation(index);
          }
        }
      else{ this.errorMsg.portfolio = 'Le titre ne doit pas dépasser 30 caractére'}
    },
    getPreviewImg(blob){
      return  URL.createObjectURL(blob);
    },
    newCreation() {
      var formData = new FormData();
      formData.append('adminId', this.session.adminId);
      formData.append('title', this.formPortfolio[0]);
      formData.append('image', this.formPortfolio[1], 'portfolio.png');
      formData.append('description', this.formPortfolio[2]);
      formData.append('type', this.formPortfolio[3])

      this.axios
        .post('/api/portfolio', formData, {headers: {'Authorization': 'Bearer '+ this.session.token}})
        .then(response => {
          console.log(response.data);
          this.formPortfolio = [null,null,null,null];
          this.updatePortfolio.img = '';
          this.errorMsg.portfolio = '';
          this.validMsg.portfolio = 'Création enregistré !';
          setTimeout(()=>{this.validMsg.portfolio = '';}, 2000);
          this.Creation();
        })
        .catch(function (error) {
          console.log(error);
          this.errorMsg.portfolio = 'Une erreur c\'est produite, veuillez réessayer !'
        });
    },
    
    modifyCreation(index){
      this.formPortfolio[0] =  this.PortfolioItem[index].title;
      this.formPortfolio[1] =  this.PortfolioItem[index].image;
      this.formPortfolio[2] =  this.PortfolioItem[index].description;
      this.formPortfolio[3] =  this.PortfolioItem[index].type;
      this.updatePortfolio.description =  this.PortfolioItem[index].description;
      this.updatePortfolio.update = true;
      this.updatePortfolio.id = index;
      this.updatePortfolio.img = this.PortfolioItem[index].image;
    },
    updateCreation(index){
      var Update;
      if(this.formPortfolio[1] === this.PortfolioItem[index].image){
        Update = new FormData();
        Update.append('adminId', this.session.adminId);
        Update.append('title', this.formPortfolio[0]);
        Update.append('image', this.formPortfolio[1]);
        Update.append('description', this.formPortfolio[2]);
        Update.append('type', this.formPortfolio[3]);
      }
      else{
        Update = new FormData();
        Update.append('adminId', this.session.adminId);
        Update.append('title', this.formPortfolio[0]);
        Update.append('image', this.formPortfolio[1], 'portfolio.png');
        Update.append('description', this.formPortfolio[2]);
        Update.append('type', this.formPortfolio[3]);
      }
      
      this.axios
        .put('/api/portfolio/'+ this.PortfolioItem[index]._id, Update,{ headers: {'Authorization': 'Bearer '+ this.session.token }})
        .then(response => {
          console.log(response.data);
          this.formPortfolio = [null,null,null,null];
          this.updatePortfolio = {
            id: Number,
            update: false,
            description: '', 
            img: '',           
          };
          this.errorMsg.portfolio = '';
          this.validMsg.portfolio = 'Création mise à jour !';
          setTimeout(()=>{this.validMsg.portfolio = '';}, 2000);
          this.Creation();
        })
        .catch(function (error) {
          console.log(error);
          this.errorMsg.portfolio = 'Une erreur c\'est produite, veuillez réessayer !'
        });
    },
    deleteCreation(id){
      this.axios
        .delete('/api/portfolio/'+ id,{ headers: {'Authorization': 'Bearer '+ this.session.token }}, { data: {'adminId': this.session.adminId}})
        .then(response => {
          console.log(response.data);
          this.Creation();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    Admin(){
      this.axios
        .post('/api/admin/get',{ data: {'adminId': this.session.adminId}}, { headers: {'Authorization': 'Bearer '+ this.session.token }})
        .then(response => {
            this.adminList = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formValidAdmin(){
      if(this.formAdmin[1] === this.formAdmin[2]){
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(regex.test(this.formAdmin[0])){
            for(let i=0; i<this.adminList.length;i++){
              if(this.formAdmin[0] === this.adminList[i].mail){
                this.errorMsg.admin = 'Adresse mail déjà utilisée !'
                return false;
              }
            }
          this.createAdmin();
          }
        else{
          this.errorMsg.admin = 'Veuillez choisir un Email valide !'
        }
      }
      else{
        this.errorMsg.admin = 'La confirmation du mot de passe est inexacte !'
      }
    },
    createAdmin(){
      var formData = {
        adminId: this.session.adminId,
        mail: this.formAdmin[0],
        password: this.formAdmin[1]
      }
      

      this.axios
      .post('/api/admin/add', formData, { headers: {'Authorization': 'Bearer '+ this.session.token }})
      .then(response => {
        console.log(response.data);
        this.formAdmin = [null,null,null];
        this.errorMsg.admin = '';
        this.validMsg.admin = 'Nouvelle adaministrateur crée !';
        this.Admin();
        setTimeout(()=>{this.validMsg.admin = '';}, 2000);
      })
      .catch(error => {
        console.log(error);
        this.errorMsg.admin = 'Une erreur c\'est produite, veuillez réessayer !'
      });
    },
    deleteAdmin(id){
      this.axios
        .delete('/api/admin/'+ id, { headers: {'Authorization': 'Bearer '+ this.session.token }}, { data: {'adminId': this.session.adminId}})
        .then(response => {
          console.log(response.data);
          this.Admin();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deconnection(){
      localStorage.removeItem('adminId');
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  margin-bottom: 3rem;
}
.table{
  max-height: 25rem;
  td{
    white-space: nowrap;
  }
}
</style>