<template>
    <div id="description" class="columns container">
        <div class="column logo">
            <img :src="require(`@/assets/${logo}`)" :alt="brandName" >
            <h1>{{brandName}}</h1>
        </div>
        
        <div class="column info">
            <slot/>
        </div>
        
        <img class="column deco" :src="require(`@/assets/${deco}`)" alt="deco">
    </div>
</template>

<script>
export default {
    props: {
        logo: String,
        brandName: String,
        deco: String,
    },
    name: 'presentation',
}
</script>

<style scoped lang="scss">
#description {
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
    padding: 0;
}   
.logo, .info, .deco{
    max-width: 33%;
}
.logo{
    img{
        width: 55%;
    }
} 
.info{
    text-align: left;
    p{
        padding-bottom: 1rem;
    }
}
.deco{
    border-radius: 2rem 0 0 2rem;
    transform: scaleX(-1);
    max-width: 25%;
}
@media only screen and (max-width: 920px){
    #description {
        padding: 1.5rem;
    }
    .logo{
        max-width: 40%;
        h1{display: none;}
        img{width: 80%;}
    }
    .info{max-width: 60%;}   
    .deco{display: none;}
}
@media only screen and (max-width: 520px){
    .logo{display: none;
    }
    .info{max-width: 100%;}
}
</style>