<template>
    <div>
        <input type="file" :id="'image' + this.idIN" ref="input" @change="handleFile()" accept=".jpg,.jpeg,.png" files="0">
        <label :for="'image' + this.idIN" :class="{add: buttonColor=='add',delete: buttonColor=='delete',save: buttonColor=='save'}">
            <svg @mouseover="buttonColor='add'" @mouseout="buttonColor=''" xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
            <button type="button" class="deleteInput" @click="deletFiles()" @mouseover="buttonColor='delete'" @mouseout="buttonColor=''" alt="deletAll" v-show="files != ''">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><circle cx="12" cy="12" r="10"></circle><line x1="16" y1="8" x2="8" y2="16"></line><line x1="8" y1="8" x2="16" y2="16"></line></svg>
            </button>
            <button @click="CreatNewImg()" v-show="files != ''" @mouseover="buttonColor='save'" @mouseout="buttonColor=''">Rogner</button>
        </label>
        <p v-if="msg" class="alert" v-html="msg"></p>
        <div v-show="files != ''" id="Cropper" >
            <div id="Crop" ref="Crop"
                v-on="{mousedown: dragStart, mouseup: dragEnd, mousemove: drag, touchstart: dragStart, touchend: dragEnd, touchmove: drag}"
            >
                <img  :src="image" id="inputImg" ref="img" @load="setTranslate(0,0,0)" draggable="false">
            </div>
            <InputNumber v-model="ZoomVal" :min="0" :max="10" @change="Zoom()" :unViewNum="true" />
        </div> 
    </div>
</template>

<script>
import InputNumber from '@/components/InputNumber'
export default {
    name: 'InputImage',
    components:{
        InputNumber
    },
    props: {
        maxFileSize: {
            type: Number,
            default: 1000000
        },
        aspectRotioX: {
            type: Number,
            default: 1,
        },
        aspectRotioY: {
            type: Number,
            default: 1,
        },
        idIN: Number,
    },
    data: () => {
        return{
            buttonColor: '',

            files:[],
            image: [],
            msg: '',
            type: '.jpg,.jpeg,.png',
            ZoomVal: 0,
            offsetZoom: 0,


            active: false,
            currentX: 0,
            currentY: 0,
            initialX: 0,
            initialY: 0,
            xOffset: 0,
            yOffset: 0,
        }
    },
    mounted() {
        this.xOffset = this.$refs.Crop.offsetTop;
        this.yOffset = this.$refs.Crop.offsetLeft;
        this.$refs.Crop.style.height = 25*(this.aspectRotioY/this.aspectRotioX) + 'rem';
    },
    methods: {
        Zoom(){
            this.setTranslate(0, 0, this.ZoomVal);
        },
        dragStart(e){
            if (e.type === "touchstart") {
                this.initialX = e.touches[0].clientX - this.xOffset;
                this.initialY = e.touches[0].clientY - this.yOffset;
            } else {
                this.initialX = e.clientX - this.xOffset;
                this.initialY = e.clientY - this.yOffset;
            }

            if (e.target === this.$refs.img) {
                this.active = true;
            }
        },
        dragEnd(){
            this.initialX = this.currentX;
            this.initialY = this.currentY;

            this.active = false
        },
        drag(e){
            if(this.active){
                e.preventDefault();
                
                var dragItemXpos = this.$refs.img.getBoundingClientRect().x;
                var CropXpos= this.$refs.Crop.getBoundingClientRect().x;
                var dragItemYpos = this.$refs.img.getBoundingClientRect().y;
                var CropYpos= this.$refs.Crop.getBoundingClientRect().y;
                
                var dragItemXbottom = this.$refs.img.getBoundingClientRect().right;
                var CropXbottom = this.$refs.Crop.getBoundingClientRect().right;
                var dragItemYbottom = this.$refs.img.getBoundingClientRect().bottom;
                var CropYbottom = this.$refs.Crop.getBoundingClientRect().bottom;

                var  dragItemWidthOriginal = this.$refs.img.width;

                var dragItemWidth = this.$refs.img.getBoundingClientRect().width;
                var CropWidth = this.$refs.Crop.getBoundingClientRect().width;
                var dragItemHeight = this.$refs.img.getBoundingClientRect().height;
                var CropHeight = this.$refs.Crop.getBoundingClientRect().height;

                if (e.type === "touchmove") {
                    this.currentX = e.touches[0].clientX - this.initialX;
                    this.currentY = e.touches[0].clientY - this.initialY;
                } 
                else {
                    this.currentX = e.clientX - this.initialX;
                    this.currentY = e.clientY - this.initialY;
                }

                //delimitation des bordure pour pas que l'image dépasse des bordures
                if(CropXpos+((dragItemWidth-(dragItemWidthOriginal))/2) < (dragItemXpos + this.currentX)){
                    this.currentX = dragItemWidth-(CropWidth*(1+(0.1*(this.ZoomVal+this.offsetZoom))));
                }
                if(CropXbottom-((dragItemWidth-(dragItemWidthOriginal))/2) > (dragItemXbottom + this.currentX) ){
                    this.currentX = (CropWidth*(1+(0.1*(this.ZoomVal+this.offsetZoom))))-dragItemWidth;
                }

                if(CropYpos-((CropHeight-(dragItemHeight))/2) < (dragItemYpos + this.currentY)){
                    this.currentY = (dragItemHeight - CropHeight)/2;
                }
                if(CropYbottom+((CropHeight-(dragItemHeight))/2) > (dragItemYbottom + this.currentY) ){
                    this.currentY = (CropHeight-dragItemHeight)/2;
                }
                this.setTranslate(this.currentX, this.currentY, this.ZoomVal);

                this.xOffset = this.currentX;
                this.yOffset = this.currentY;
            }
        },
        setTranslate(xPos, yPos, scale){
            var dragItem = this.$refs.img;
            var Crop = this.$refs.Crop;
            if(dragItem.height < Crop.clientHeight){//mise à l'echelle de l'image pour les image avec une hauteur inférieur au Croppeur
                while((dragItem.height*(1+(0.2*this.offsetZoom)))< Crop.clientHeight){
                    this.offsetZoom++;
                }
            }
            dragItem.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0) scale("+ (1+(0.2*(scale + this.offsetZoom))) + ")";
        },
        handleFile(){// recupération de l'image
            this.files = [];
            this.image = '';
            this.msg = '';
            this.ZoomVal = 0;
            this.offsetZoom = 0;


            this.active = false;
            this.currentX = 0;
            this.currentY = 0;
            this.initialX = 0;
            this.initialY = 0;
            this.xOffset = 0;
            this.yOffset = 0;


            this.files = event.target.files[0];

            var extension = this.files.name.slice(this.files.name.lastIndexOf('.'));

            if(this.type.match(extension)){
                if(this.files.size < this.maxFileSize){
                    
                    this.image = URL.createObjectURL(this.files);
                }
                else{
                    this.msg = 'L\'image ne doit pas dépasser '+ (this.maxFileSize/1000000) +' Mo ! <br> <a href="https://www.iloveimg.com/fr"  target="_blank">Comprésser mon image</a>';
                }
            }
            else{
                this.msg = 'Extension invalide';
            }

            if(this.msg){
                this.$refs.input.value = '';
                this.files = [];
                this.image = [];
            }
        },
        CreatNewImg(){// création d'une nouvelle image à partir des offset ente le croppeur et l'image
            var ImgSource = this.$refs.img;
            var Crop = this.$refs.Crop;
            var scale = (1+(0.2*(this.ZoomVal+this.offsetZoom)));
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var x =  ((Crop.getBoundingClientRect().x - ImgSource.getBoundingClientRect().x)/scale) * (ImgSource.naturalWidth/Crop.clientWidth);
            var y =  ((Crop.getBoundingClientRect().y - ImgSource.getBoundingClientRect().y)/scale) * (ImgSource.naturalWidth/Crop.clientWidth);
            var Width = ((ImgSource.getBoundingClientRect().width - (ImgSource.getBoundingClientRect().width-Crop.clientWidth))/scale) * (ImgSource.naturalWidth/Crop.clientWidth);
            var Height = ((ImgSource.getBoundingClientRect().height - (ImgSource.getBoundingClientRect().height-Crop.clientHeight))/ scale) * (ImgSource.naturalWidth/Crop.clientWidth);
            canvas.setAttribute('width',Width);
            canvas.setAttribute('height',Height);

            ctx.drawImage(ImgSource, x, y, Width, Height, 0, 0, Width, Height);
            canvas.toBlob(blob => { 
                this.$emit('input', blob); //retour d'un objet blob contenant l'image
                this.deletFiles();
            },'image/jpeg');

        },
        deletFiles() { //reset du croppeur
            this.$refs.input.value = '';
            this.files = [];
            this.image = [];
            this.msg = '';
            this.ZoomVal = 0;
            this.offsetZoom = 0;


            this.active = false;
            this.currentX = 0;
            this.currentY = 0;
            this.initialX = 0;
            this.initialY = 0;
            this.xOffset = 0;
            this.yOffset = 0;
        },
    },
}
</script>

<style scoped lang="scss">
#Crop{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25rem;
    height: 20rem;
    margin: auto;
    border-radius: 0.5rem;
    background-color: white;
    overflow: hidden;
    cursor: move;

    #inputImg{
        display: block;
        width: 100%;
    }
}

.deleteInput{
    margin-bottom: -1rem;
    cursor: pointer;
}
input[type="file"]{
    display: none;
}
label{
    display: inline-block;
    margin: 0.5rem;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    background: rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    button{
        border-left: 1px solid currentColor;
        padding-left:0.5rem;
        margin-left: 0.5rem;
    }
    @media (hover: hover) {
        svg:hover{color: white;}
        button:hover{ color: white;}
        &.add{
            background: linear-gradient(-135deg, #11998e, #38ef7d);
        }
        &.delete{
            background: linear-gradient(to right, #ed213a, #93291e);
        }
        &.save{
            background: $deco-color;
        }
    }
}
svg{
    margin-bottom: -0.3rem;
}
</style>