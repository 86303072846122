<template>
    <section>
        <div class="container">
            <h2>Connection à la page Administrateur</h2>
            <form action="javascript:void(0);">
                <input type="mail" placeholder="Mail :" v-model="formConnection[0]" /><br>
                <input type="password" placeholder="Mot de passe :" v-model="formConnection[1]"/>
                <vue-recaptcha sitekey="6LdfgwYaAAAAAO5rSTzfkJChYIAoQzfqrkfiCNiV" :loadRecaptchaScript="true">
                    <button type="submit" class="cta-button" @click="Connection()">Connection</button>
                </vue-recaptcha>
                <p class="alert" v-show="errorMsg !== ''">{{errorMsg}}</p>
            </form>
        </div>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
    components: { VueRecaptcha },
    data: () => {
        return {
            formConnection: [null, null],
            errorMsg: '',
        }
    },
    methods: {
        Connection(){
            for(let i=0; i < this.formConnection.length; i++){
                if (this.formConnection[i] === null || this.formConnection[i] === '') {
                this.errorMsg = 'Veuillez remplir tout les champs'
                return false;
                }
            }
            var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!regex.test(this.formConnection[0])){
                this.errorMsg = 'Veuillez choisir un Email valide !';
                return false;
            }

            var formData = {
                mail: this.formConnection[0],
                password: this.formConnection[1]
            }

            this.axios
            .post('/api/admin/login', formData)
            .then(response => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('adminId', response.data.adminId);
                this.$router.push('/admin');
            })
            .catch(() => {this.errorMsg = 'Email ou mot de passe invalide !'});
        }
    }
}
</script>
<style scoped lang="scss" >

section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>