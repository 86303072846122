<template>
    <div class="dropdown">
        <div class="drop-title">
            {{dropTitle}}
            <span class="drop-ico"></span>
        </div>
        

        <div class="drop-content" ref="dropContent">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dropTitle: String,
    },
}
</script>

<style scoped lang="scss">
.dropdown {
    display: inline-block;
    width: max-content;
    width: intrinsic;
    margin: 0.5rem auto;
    position: relative;
    height: 2.8rem;
    padding: 0.5rem;
    background-color: white;
    border-radius: 0.5rem;
    text-align: left;
    cursor: pointer;
}
.drop-title {
    display: block;
}
.drop-ico {
    content: '';
    float: right;
    margin: 0.4rem 0.5rem 0 1rem;
    border: solid #333;
    border-width: 0 0.2rem 0.2rem 0;
    border-radius: 0.1rem;
    padding: 0.3rem;
    transition: all 0.25s ease-in-out;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.drop-content {
    display: none;
    position: absolute;
    width: max-content;
    width: intrinsic;
    top: 2.8rem;
    margin-left: -0.5rem;
    padding: 1rem;
    overflow: hidden;
    z-index: 10;
    background-color: #fff;
    border: 1px solid $background-color;
    box-shadow: 0 0 1.5rem rgba(0,0,0,.1);
    border-radius: 0.5rem ;
}
.dropdown:hover .drop-content{
    display: block;
}
.dropdown:hover .drop-ico {
    margin-top: 0.6rem;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
</style>