<template>
  <div id="app">
    <img src="@/assets/AE.gif" alt="Logo animé" id="loader">
    <Navigation :navLinks="navLinks" :navSocial="navSocial" :logo="logo" :brandName="brandName"/>
    <main>
        <Presentation :logo="logo" :brandName="brandName" :deco="deco">
            <p>Graphiste | Webmaster</p>
            <h2>Créateur de contenu.</h2>
            <p>
                Je m’appelle Adam El Idrissi.
                Je suis graphiste, webmaster en freelance.
            </p>
            <p>
                Je suis basé à Tours et j’aide les entreprises à concevoir
                l’image de marque qui leur correspond.
            </p>
        </Presentation>

        <div id="portfolio" class="container">
            <h2 style="padding-bottom: 1rem;">Portfolio</h2>
            <Dropdown dropTitle="Type de projets">
                <label class="check">tous
                    <input type="radio" name="category" checked="checked" value="all" v-model="categoryPorfolio">
                    <span class="checkmark"></span>
                </label><br>
                <label class="check">logo
                    <input type="radio" name="category" value="logo" v-model="categoryPorfolio">
                    <span class="checkmark"></span>
                </label><br>
                <label class="check">web design
                    <input type="radio"  name="category" value="web" v-model="categoryPorfolio">
                    <span class="checkmark"></span>
                </label><br>
                <label class="check">Autres
                    <input type="radio"  name="category" value="other" v-model="categoryPorfolio">
                    <span class="checkmark"></span>
                </label>
            </Dropdown>
            <Slider :itemPerSlide="3" :nbSlide="filterPortfolio(PortfolioItem).length" >
                <PortfolioSlide v-for="item in filterPortfolio(PortfolioItem)" :key="item.title" :imgPortfolio="item.image" :titlePortfolio="item.title" :descriptionPortfolio="item.description" :nbSlide="filterPortfolio(PortfolioItem).length"/>
            </Slider>
            <a class="cta-button"  @click="Contact()">Travailler avec moi !</a>
        </div>
        <div class="columns">
            <div id="about" class="container column">
                <h2>À propos</h2>
                <Slider :itemPerSlide="1" :nbSlide="3" btnStyle="S2">
                    <div class="aboutSlide">
                        <h3>Qui ?</h3>
                        <p>
                            Jeune <b>indépendant</b>, j’ai la chance de pouvoir faire de ma passion pour les <b>créations visuels</b> un métier à temps plein, riche et varié.<br>
                            Dans mon quotidien, mes moteurs sont les rencontres et la <b>découverte de nouveaux univers</b> auxquels je peux <b>donner vie</b> à travers mes créations.
                        </p>
                    </div>
                    <div class="aboutSlide">
                        <h3>Quoi ?</h3>
                        <p>
                            <b>Perfectionniste</b> dans l’âme, j’ai rapidement développé mon savoir-faire dans différents domaines liés à la <b>création graphique</b> ; histoire de pouvoir offrir une <b>gamme complète</b> de services créatifs sur mesure en web design, identité graphique, illustrations, typographie et bien plus encore.
                        </p>
                    </div>
                    <div class="aboutSlide">
                        <h3>Comment ?</h3>
                        <p>
                            Avant toute proposition, je m’efforce de comprendre au mieux <b>vos objectifs</b> afin de donner à votre projet une <b>identité unique</b> (ou qui vous ressemble) en terme d’image et de <b>positionnement</b>.
                            Cette identité peut se décliner de différentes façons en fonction de vos besoins allant de la création d’un <b>logo</b>, au <b>site web</b> jusqu’à la réalisation d'une <b>oeuvre graphique</b>.									
                        </p>
                    </div>
                </Slider>
            </div>
            <div id="contact" class="container column">
					<h2>Contact</h2>
					<img :src="profil.image" alt="image de profile du graphiste " loading="lazy">
					<p>
						<b>Tel:</b> <a :href="'tel:' + profil.tel">{{profil.tel}}</a><br>
						<b>Mail:</b> <a :href="'mailto:' + profil.mail">{{profil.mail}}</a>
					</p>
			</div>
        </div>
        <div id="services" class="container">
            <h2>Services & Tarifs</h2>
            <Onglets>
                <Onglet name="Logo  identité de marque" selected="true">
                    <LogoServices/>
                </Onglet>
                <Onglet name="Web dévelopement">
                    <WebServices/>
                </Onglet>
                <Onglet name="Autres services">
                    <h3>À venir !</h3>
                </Onglet>
            </Onglets>
            <a class="cta-button" @click="Contact()">Travailler avec moi !</a>
        </div>
    </main>
    <footer>
        <cite>© 2020 Tous droits réservés | AEdesign - 4 Rue Capitaine Finance - 25310 Hérimoncourt <a href="/mentions">Mentions l'égales</a></cite>
    </footer>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'
import Presentation from '@/components/Presentation'
import Dropdown from '@/components/Dropdown'
import Slider from '@/components/Slider'
import PortfolioSlide from '@/components/PortfolioSlide'
import Onglets from '@/components/Onglets'
import Onglet from '@/components/Onglet'
import LogoServices from '@/components/LogoServices'
import WebServices from '@/components/WebServices'

export default {
    name: 'App',
    components: {
        Navigation,
        Presentation,
        Dropdown,
        Slider,
        PortfolioSlide,
        Onglets, Onglet,
        LogoServices,
        WebServices,
    },
    data: () => ({
        logo: 'AE-logo-black.png',
        brandName: 'AEdesign',
        profil: {},
        deco: 'Deco.png',
        navLinks: [
        {name: 'Portfolio',         path: 'portfolio'},
        {name: 'À propos',          path: 'about' },
        {name: 'Contact',           path: 'contact'},
        {name: 'Services & Tarifs', path: 'services'}
        ],
        navSocial: [
        {icon: 'Insta-icon.svg', name: 'Instagram', path: 'https://www.instagram.com/aedesignfr/'},
        {icon: 'icon/linkedin.svg',    name: 'Linkedin',  path: 'https://www.linkedin.com/in/adam-el-idrissi-ba0049202/' },
        {icon: 'malt.svg',       name: 'Malt',      path: 'https://www.malt.fr/profile/adamelidrissi1'},
        ],
        PortfolioItem: {},
        categoryPorfolio: 'all',
    }),
    mounted(){
        this.axios
            .get('/api/portfolio')
            .then(response => {
                this.PortfolioItem = response.data.slice().reverse();
            })
            .catch(function (error) {
                console.log(error);
            });
                
        this.axios
            .get('/api/profil')
            .then(response => {
                this.profil = response.data[0];
                this.profil;
            })
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(showPage, 3000);

        function showPage() {
            document.getElementById("loader").style.display = "none";
            document.getElementsByTagName("header")[0].style.opacity = "100%";
            document.getElementsByTagName("main")[0].style.display = "block";
            document.getElementsByTagName("footer")[0].style.display = "block";
        }
    },
    methods: {
        filterPortfolio(items){
            if(this.categoryPorfolio === 'all'){
                return items;
            }
            else{
                return items.filter(item => {
                    return (item.type === this.categoryPorfolio);
                });  
            }
        },
        Contact(){
            var elem = document.getElementById('contact');
            var elemY = elem.offsetTop - 65;
            window.scrollTo(elem.offsetLeft,elemY); 
            elem.classList.add('Vue');  
            setTimeout(() => {
                elem.classList.remove('Vue');                
            }, 2000);
        }
    }
}
</script>

<style scoped lang="scss">
#contact::before,
#contact::after{
    background-image: url('../assets/Deco.png');
}
.aboutSlide{
    text-align: left;
    width: 100%;
    h3{
        padding-bottom: 1rem;
    }
}
#about, #contact{
    max-width: calc(50% - 3rem);
}
//=========================Contact=========================
#contact{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
#contact::before,
#contact::after{
    content: '';
    position: absolute;
    width: 25%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
}
#contact::before{
    top: 40%;
    left: 0;
}
#contact::after{
    transform: scale(-1);
    top: -40%;
    left: 75%;
}
#contact img{
    width: 15rem;
    height: 15rem;
    z-index: 5;
    margin: 1rem auto;
    border-radius: 50%;
    border: 5px solid white;
}
@media only screen and (max-width: 720px){
    #about, #contact{
        max-width: calc(100% - 3rem);
    }
}
/*===================Mise en valeur===================*/
.Vue p{
    animation: vue 1s ease-in-out infinite;
    width: fit-content;
    width: moz-fit-content;
    padding: 0 1rem;
    background-image: $deco-color;
    background-clip: text;
    margin: auto;
}
@keyframes vue {
    50%{
        color: transparent;
        transform: scale(1.1);
    }
}
</style>
